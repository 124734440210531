<template>
  <!-- <div>
    <pre>{{ loggedUser }}</pre> -->
  <b-spinner v-if="isLoading" class="d-block mx-auto text-primary my-2" />
    <layout-horizontal v-else>
      <router-view />
    </layout-horizontal>
  <!-- </div> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NectiosLogo from '@/assets/images/placeholders/light/nectios-favicon.png';
import LayoutHorizontal from '@/layouts/horizontal/LayoutHorizontal.vue';

export default {
  name: 'MyNectiosRoot',

  components: {
    LayoutHorizontal
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters(['loggedUser']),

    faviconURL() {
      return NectiosLogo;
    },
    isNexosBancolombia() {
      // console.log('process.env.VUE_APP_CUSTOMER', process.env.VUE_APP_CUSTOMER);
      return process.env.VUE_APP_CUSTOMER === 'nexos';
    },
  },

  async created() {
    // console.log('Created MyNectiosRoot.vue')

    this.isLoading = true;

    if (!this.loggedUser.id) {
      await this.loadLoggedUser();
    }
    // console.log('loggedUser', this.loggedUser);

    if(this.isNexosBancolombia){
      // console.log('isNexos');
      let nexosSpaceUrl = process.env.VUE_APP_URL + '/' + process.env.VUE_APP_MAIN_SPACE_SLUG;
      if(!this.loggedUser.id) {
        nexosSpaceUrl += '/auth/login';
      }
      // console.log('MyNectiosRoot.vue - Redirecting to Nexos Space:', nexosSpaceUrl);
      window.location.href = nexosSpaceUrl;
    }
    // await this.showChatbot();
    this.isLoading = false;
    //console.log('FINAL ROOT.vue')
  },

  methods: {
    ...mapActions(['fetchLoggedUser2']),

    async loadLoggedUser() {
      // await this.$store.dispatch('fetchLoggedUser');
      await this.fetchLoggedUser2();
      document.title = this.isNexosBancolombia ? 'Nexos' : 'My Nectios';
      const links = document.querySelectorAll("link[rel~='icon']");
      for (let index = 0; index < links.length; index += 1) {
        const link = links[index];
        link.href = this.faviconURL;
      }
    },

    // async showChatbot() {
    //   // const user = await this.$store.dispatch('fetchLoggedUser');
    //   const chatBot = document.createElement("script");
    //   chatBot.innerText =
    //       'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
    //   document.body.appendChild(chatBot);
    // },
  },
};
</script>
